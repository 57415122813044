'use strict';

var siteInit = {

	DOMready: function() {

		if ( $('#js-wrapper').length > 0 ) {
			//siteInit.barba();
		}

		if ( $('.js-slider--primary').length > 0 ) {
			siteInit.sliderPrimary();
		}

		if ( $('.js-slider--secondary').length > 0 ) {
			siteInit.sliderSecondary();
		}

		if ( $('.js-slider--tertiary').length > 0 ) {
			siteInit.sliderTertiary();
		}

		if ( $('.js-tabs').length > 0 ) {
			siteInit.tabs();
		}

		if ( $('.js-carousel').length > 0 ) {
			siteInit.carousel();
		}

		if ( $('.js-carousel--secondary').length > 0 ) {
			siteInit.carouselSecondary();
		}

		if ( $('.js-carousel--tertiary').length > 0 ) {
			siteInit.carouselTertiary();
		}

		if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
			siteInit.modal();
		}

		if ( $('.js-navicon').length > 0 ) {
			siteInit.mobileNavigation();
		}

		if ( $('.skip-menu').length > 0 ) {
			siteInit.navigationSidebar();
		}

		if ( $('#retailers__map-container').length > 0 ) {
			siteInit.retailers();
		}

		if ( $('.js-popup').length > 0 ) {
			siteInit.popUp();
		}

		siteInit.cookie();

	},

	DOMload: function() {

		if ( $('.js-loader').length > 0 ) {
			//siteInit.loader();
		}

	},


	// Mobile sidebar navigation
	navigationSidebar: function() {

		$('body').on( 'click', '.skip-menu', function(e) {
			e.preventDefault();
			$('.expCatalogueCategory1').toggleClass('is-active');
			$(this).toggleClass('is-active');
		});

	},


	// Loader
	loader: function() {

		$("body").removeClass('is-loading').addClass('is-loaded');
		$(".js-loader").fadeOut('slow');

	},

	// Cookie law
	cookie: function () {

		var lang, cookiePage;

		switch ( $('html').attr('lang') ) {
			case "it-IT":
				lang = "it";
				cookiePage = "/privacy.asp";
			break;
			case "en-US":
				lang = "en";
				cookiePage = "/eng/privacy.asp";
		}

		Yacc.configure({
			cookiePageUrl: cookiePage,
			culture: lang,
		});

	},


	// Carousel
	carousel: function() {

		$(".js-carousel").owlCarousel({
			margin: 10,
			loop:true,
			nav:false,
			dots:false,
			autoplay: true,
			responsive : {
				0 : {
					items: 1,
				},
				992 : {
					items: 2,
				}
			}
		});

	},


	// Carousel Secondary
	carouselSecondary: function() {

		$(".js-carousel--secondary").owlCarousel({
			margin: 10,
			loop:false,
			nav:false,
			dots:true,
			responsive : {
				0 : {
					items: 1,
				},

				480 : {
					items: 2,
				},
				992 : {
					items: 4,
				}
			}
		});

	},


	// Carousel Teriary
	carouselTertiary: function() {

		$(".js-carousel--tertiary").owlCarousel({
			margin: 10,
			loop:true,
			autoplay:true,
			nav:false,
			//URLhashListener:false,
			dots:false,
			items: 1
			//startPosition: 'URLHash'
		});

	},


	// Tabs
	tabs: function() {
		$('.js-tabs').tabslet();
	},

	// Slider
	sliderPrimary: function() {

		$(".js-slider--primary").owlCarousel({
			items: 1,
			loop:true,
			nav:true,
			dots:false,
			autoplay: true
		});

	},

	// Slider
	sliderSecondary: function() {

		$(".js-slider--secondary").owlCarousel({
			items: 1,
			loop:true,
			nav:true,
			dots:false,
			onChanged: callback,
			autoplay: true
		});

		function callback(event) {
			// Provided by the core
			var element   = event.target;         // DOM element, in this example .owl-carousel
			var name      = event.type;           // Name of the event, in this example dragged
			var namespace = event.namespace;      // Namespace of the event, in this example owl.carousel
			var items     = event.item.count;     // Number of items
			var item      = event.item.index;     // Position of the current item
			// Provided by the navigation plugin
			var pages     = event.page.count;     // Number of pages
			var page      = event.page.index;     // Position of the current page
			var size      = event.page.size;      // Number of items per page
		}


		$('.js-slide-preview__link').on('click', function(e) {
			e.preventDefault();
		    var index = $(this).data('item');
		    $(".js-slider--secondary").trigger("to.owl.carousel", [index, 1, true]);
		});

	},


	// Slider
	sliderTertiary: function() {

		$(".js-slider--tertiary").owlCarousel({
			items: 1,
			loop:true,
			nav:false,
			dots:false,
			URLhashListener:false,
			autoplay: true
		});

	},

	// Modal
	modal: function() {

		$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
			transition: 'elastic',
			speed: 400,
			opacity: 0.8,
			slideshow: true,
			slideshowSpeed: 4000,
			itemsnitialWidth: 50,
			initialHeight: 50,
			maxWidth: '90%',
			maxHeight: '90%',
		});

	},

	// Retailers
	retailers: function() {

		var productsContainer = $('.retailers__products');

		productsContainer.addClass('is-loading');

		$('#retailers__map-container').storeLocator({
			'mapID' : 'retailers__map',
			'locationList' : 'retailers__list-container',
			'formContainer' : 'retailers__form-container',
			'formID' : 'retailers__form',
			'addressID' : 'retailers__input',
			'regionID' : 'bh-sl-region',
			'autoComplete': true,
			'dataType': 'json',
			'fullMapStart': true,
			'storeLimit' : -1,
			'distanceAlert' : -1,
			'autoGeocode': false,
			'lengthUnit' : 'km',
			'maxDistance' : true,
			'callbackSuccess': function() {
				$('.retailers__products').removeClass('is-loading');
			},
			'callbackListClick' : function( markerId, selectedMarker) {
				//alert(markerId);
				productsContainer.addClass('is-loading').removeClass('is-loaded');
				var idAgente = $('.retailers__list-container li').eq(markerId).data('reseller-id');

				//function getContent(idAgente) {
 					$.get( "ajaxservices/BonominiAgentProductList.asp", {
 						idAgente: idAgente,
 						InstId: 5
 					}, function( data ) {
 						//console.log(data);
						productsContainer.removeClass('is-loading').addClass('is-loaded');
						$('.retailers__products-content').html(data);
 					});
				//}
			},
			'maxDistanceID' : 'bh-sl-maxdistance',
			'dataLocation': '/ajaxservices/DirectoryGetLocationJson.asp?instid=39',
			'infowindowTemplatePath' : '/Template/bonomini/data/infowindow-description.html',
			'listTemplatePath' : '/Template/bonomini/data/location-list-description.html'
		});

	},

	popUp: function () {

		$('.js-popup').showElement({
     		hideClass: 'popup--hidden'
		});

	},

	// Mobile navigation
	mobileNavigation: function() {

		$('body').on( 'click', '.js-navicon', function( event ) {

			// Verificare se si puo usare il target CSS al posto di fare il toggle via js
			var $el = $(this).add('#navigation');
			$( $el ).toggleClass('is-active');
			event.preventDefault();

		});

	}

};


$( document ).ready( function() {
	siteInit.DOMready();
});

$( window ).load( function() {
	siteInit.DOMload();
});
